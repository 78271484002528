import 'ckeditor5/ckeditor5.css';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { getAnalytics, isSupported } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, RouteObject, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { FullScreenLoader } from './common/components/full-screen-loader';
import {
  ENV_CONFIG_FIREBASE_API_KEY,
  ENV_CONFIG_FIREBASE_APP_ID,
  ENV_CONFIG_FIREBASE_AUTH_DOMAIN,
  ENV_CONFIG_FIREBASE_MEASUREMENT_ID,
  ENV_CONFIG_FIREBASE_MESSAGING_SENDER_ID,
  ENV_CONFIG_FIREBASE_PROJECT_ID,
  ENV_CONFIG_FIREBASE_STORAGE_BUCKET,
} from './common/constants/env';

const LazyAuthLayout = React.lazy(() => import('./layouts/auth'));
const LazyPrivateLayout = React.lazy(() => import('./layouts/private'));

const App = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const authRoutes: RouteObject = useMemo(
    () => ({
      path: '/auth/*',
      element: <LazyAuthLayout />,
    }),
    [],
  );

  const privateRoutes: RouteObject = useMemo(
    () => ({
      path: '/app/*',
      element: <LazyPrivateLayout />,
    }),
    [],
  );

  const defaultRoutes: RouteObject = useMemo(
    () => ({
      path: '*',
      element: <Navigate to={`/auth${search}`} />,
    }),
    [search],
  );

  const routing = useRoutes([defaultRoutes, authRoutes, privateRoutes]);

  useMemo(() => {
    const firebaseConfig = {
      apiKey: ENV_CONFIG_FIREBASE_API_KEY,
      authDomain: ENV_CONFIG_FIREBASE_AUTH_DOMAIN,
      projectId: ENV_CONFIG_FIREBASE_PROJECT_ID,
      storageBucket: ENV_CONFIG_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: ENV_CONFIG_FIREBASE_MESSAGING_SENDER_ID,
      appId: ENV_CONFIG_FIREBASE_APP_ID,
      measurementId: ENV_CONFIG_FIREBASE_MEASUREMENT_ID,
    };
    initializeApp(firebaseConfig);
    isSupported().then((supported) => (supported ? getAnalytics() : null));
  }, []);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      const currentRoute = window.location.pathname;
      if (user) {
        if (currentRoute.startsWith('/app')) {
          navigate(currentRoute);
        } else {
          navigate('/app/dashboard');
        }
      } else {
        if (currentRoute.startsWith('/auth')) {
          navigate(currentRoute);
        } else {
          navigate('/auth/login');
        }
      }
      setIsLoading(false);
    });
  }, [navigate]);

  dayjs.extend(localizedFormat);
  dayjs.extend(quarterOfYear);
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return <React.Suspense fallback={<FullScreenLoader />}>{routing}</React.Suspense>;
};

export default App;
